<template>
  <div class="project-teams">
    <ConfirmDialog ref="confirmDialog" />

    <CCard class="project-teams__card">
      <CCardBody class="project-teams__card-body">
        <CCol class="col-12 col-lg-12 col-xl-8">
          <BuilderSteps class="project-teams__steps" :step="projectStep" />
        </CCol>
        <h3>{{projectName}}</h3>

        <div class="project-teams__header">
          <h2 class="project-teams__heading">Team members</h2>

          <div class="project-teams__actions">
            <CButton
              class="project-teams__header-button"
              color="primary"
              variant="outline"
              :disabled="!selectedUsers.length"
              @click="removeSelectedUsersHandler"
            >
              <span>Remove all selected</span>
            </CButton>

            <CButton
              class="project-teams__header-button"
              color="primary"
              @click="openModal"
            >
              <CIcon
                name="cil-plus"
                class="project-teams__icon project-teams__icon--plus"
              />
              <span>Add users</span>
            </CButton>
          </div>
        </div>

        <div class="project-teams__table">
          <SmartTable
            :items="existingUsers"
            :items-per-page="parseInt(perPage)"
            :columns="teamTableColumns"
            :selectable="canEdit ? 'user_id' : undefined"
            :selected-items="selectedUsers"
            :loading="loadingTable"
            :active-page="activePage"
            :per-page="perPage"
            :pages="pages"
            :sorter-value="sort"
            @selected-items-change="updateSelectedUsers"
            @sorter-change="onSort"
            @update-active-page="(value) => (activePage = value)"
            @update-per-page="(value) => (perPage = value)"
          >
            <template #clear_text>
              <div class="project-teams__table-clear">
                <h2 class="project-teams__table-heading">
                  There is no team for this project yet!
                </h2>

                <p class="project-teams__table-subtitle">
                  But we are almost there! Let’s add the right collaborators to
                  manage this projects
                </p>

                <img
                  class="project-teams__table-image"
                  src="@/assets/images/clear-text.jpg"
                />
              </div>
            </template>
          </SmartTable>
        </div>

        <FixedBottomPanel
          :buttons="fixedBottomPanelButtons"
          @deleteHandler="deleteProjectHandler"
        />
      </CCardBody>
    </CCard>

    <Modal
      v-model="searchQuery"
      :visible="visibleModal"
      :not-added-users="notAddedUsers"
      @sort="setModalSort"
      @closeModal="closeModal"
      @modalHandler="modalHandler"
    />
  </div>
</template>

<script>
import { debounce } from '@/utils/debounce'
import { isEqual } from 'lodash/lang'
import table from '@/mixin/table'
import Modal from './Modal'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import BuilderSteps from '@/views/Projects/Project/Components/BuilderSteps'
import FixedBottomPanel from '@/views/Projects/Project/Components/FixedBottomPanel'
import { mapGetters } from 'vuex'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'Index',
  components: { FixedBottomPanel, Modal, ConfirmDialog, BuilderSteps },
  mixins: [table],
  inject: ['toast'],
  async beforeRouteLeave() {
    if (this.isEditForm) {
      await this.$refs.confirmDialog
        .confirm({
          text: this.$t('messages.unsaved'),
          confirmText: 'Leave',
          cancelText: 'Cancel',
          reverse: true,
        })
        .then((response) => {
          if (response) {
            return true
          } else {
            return Promise.reject(false)
          }
        })
    } else {
      return true
    }
  },
  data() {
    return {
      existingUsers: [],
      notAddedUsers: [],

      teamTableColumns: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'role',
          label: 'Role',
        },
        {
          key: 'job_title',
          label: 'Job',
        },
        {
          key: 'can_approve_and_assign',
          label: 'Signer',
          format: (value) => (value ? 'Yes' : 'No'),
        },
        {
          key: 'office_name',
          label: 'Office',
        },
        {
          key: 'email',
          label: 'Email',
          email: true,
          sorter: false,
        },
        {
          key: 'phone',
          label: 'Phone',
          sorter: false,
        },
      ],

      selectedUsers: [],

      modalTableSort: { column: '', state: '' },

      searchQuery: '',

      visibleModal: false,

      projectName: '',
      projectStep: 1,

      originProjectName: '',
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'projectPermissions',
    }),
    canEdit() {
      return this.permissions.editOrCreate
    },
    fixedBottomPanelButtons() {
      return [
        {
          text: 'Cancel',
          disabled: !this.isEditForm,
          outline: true,
          event: () => this.handleCancel(),
        },
        {
          text: 'Save',
          disabled: !this.existingUsers.length,
          outline: true,
          event: this.saveSecondStep,
        },
        {
          text: 'Save & next',
          disabled: !this.existingUsers.length,
          event: this.saveSecondStepAndGoNext,
        },
      ]
    },
    isEditForm() {
      return !isEqual(this.projectName, this.originProjectName)
    },
  },
  watch: {
    searchQuery: debounce(function (query) {
      this.fetchNotAddedTeam(query)
    }, 400),
  },
  methods: {
    async fetchData() {
      await this.$http.projects
        .getProjectInfo(this.$route.params.id)
        .then(({ data: { data } }) => {
          this.projectStep = data.step
          this.projectName = data.name

          this.originProjectName = data.name
        })

      this.fetchExistTeam()
      this.fetchNotAddedTeam()
    },
    fetchExistTeam() {
      this.loadingTable = true
      return this.$http.projects
        .fetchTeam({
          params: {
            project_id: this.$route.params.id,
            per_page: this.perPage,
            page: this.activePage,
            search: '',
            sort_field:
              this.sort && this.sort.column ? this.sort.column : 'created_at',
            sort_direction:
              this.sort && this.sort.state ? this.sort.state : 'desc',
            status: 'internal',
          },
        })
        .then((response) => {
          this.existingUsers = response.data.data
          this.pages = response.data.meta.last_page
        })
        .finally(() => {
          this.loadingTable = false
        })
    },
    fetchNotAddedTeam(query = '') {
      return this.$http.companies
        .getUsersNotAddToProject({
          params: {
            project_id: this.$route.params.id,
            per_page: -1,
            page: 1,
            search: query,
            sort_field:
              this.modalTableSort && this.modalTableSort.column
                ? this.modalTableSort.column
                : 'created_at',
            sort_direction:
              this.modalTableSort && this.modalTableSort.state
                ? this.modalTableSort.state
                : 'desc',
          },
        })
        .then((response) => {
          this.notAddedUsers = response.data.data
        })
        .finally(() => {})
    },

    setModalSort(sort) {
      this.modalTableSort = sort
      this.fetchNotAddedTeam(this.searchQuery)
    },

    onSort(sort) {
      this.sort = sort
      this.fetchExistTeam()
    },

    generationFormDataForUserMoving(selectedUsers) {
      const formData = new FormData()
      formData.append('project_id', this.$route.params.id)
      selectedUsers.forEach((item) => {
        formData.append('users[]', item)
      })

      return formData
    },
    removeSelectedUsersHandler() {
      if (!this.permissions.editOrCreate) {
        this.$notify(permissionError)
        return false
      }
      this.$refs.confirmDialog
        .confirm({
          text: 'Are you sure you want to remove selected users from the project?',
          cancelText: 'Cancel',
          confirmText: 'Yes, remove',
          reverse: true,
        })
        .then((response) => {
          if (response) {
            this.removeSelectedUsers()
          }
        })
    },
    removeSelectedUsers() {
      this.$http.projects
        .teamRemove(this.generationFormDataForUserMoving(this.selectedUsers))
        .then(() => {
          this.toast('success', 'The user has been removed from the team')
          this.selectedUsers = []
          this.fetchData()
        })
        .catch((error) => {
          const errors = Object.values(error.response.data.errors).flat() || []

          if (!errors.length) return

          errors.forEach((el) => {
            this.toast('error', el)
          })
        })
    },
    addSelectedUsers(selectedUsers) {
      return this.$http.projects
        .teamAdd(this.generationFormDataForUserMoving(selectedUsers))
        .then(() => {
          this.toast(
            'success',
            'The user has been added to the team successfully',
          )
          this.fetchData()
        })
    },

    saveSecondStep(data) {
      const redirect = data?.redirect
      return this.$http.projects
        .saveSecondStep(this.$route.params.id, {
          project_name: this.projectName,
        })
        .then(({ data: { data } }) => {
          this.toast('success', 'Changes have been saved successfully')
          this.projectStep = data.step
          this.projectName = data.name
          this.originProjectName = data.name

          if (redirect) {
            this.$router.push({
              name: 'ProjectScopeWorkflow',
              params: { id: this.$route.params.id },
            })
          }
        })
        .catch(({ response }) => {
          this.toast('error', response.data.message)
        })
    },
    saveSecondStepAndGoNext() {
      this.saveSecondStep({ redirect: true })
    },
    deleteProjectHandler() {
      this.$refs.confirmDialog
        .confirm({
          text: `Are you sure you want to delete project "${this.originProjectName}", delete all related scopes and revoke all related tasks and contract assignments?`,
          cancelText: 'Cancel',
          confirmText: 'Yes, delete',
          reverse: true,
        })
        .then((response) => {
          if (response) {
            this.deleteProject()
          }
        })
    },
    deleteProject() {
      this.$http.projects
        .deleteProject(this.$route.params.id)
        .then((response) => {
          this.toast('success', response.data.message)
          this.$router.push({ name: 'ProjectsList' })
        })
        .catch(({ response }) => {
          this.toast('error', response.data.message)
        })
    },

    openModal() {
      !this.permissions.editOrCreate
        ? this.$notify(permissionError)
        : (this.visibleModal = true)
    },
    closeModal() {
      this.visibleModal = false
      this.searchQuery = ''
    },
    modalHandler(selectedUsers) {
      this.addSelectedUsers(selectedUsers).finally(() => {
        this.closeModal()
      })
    },

    updateSelectedUsers(s) {
      this.selectedUsers = s
    },

    async handleCancel() {
      if (this.isEditForm) {
        await this.$refs.confirmDialog
          .confirm({
            text: this.$t('messages.unsaved'),
            cancelText: 'Cancel',
            confirmText: 'OK',
            reverse: true,
          })
          .then((res) => {
            if (res) {
              this.cancel()
            }
          })
      } else {
        this.cancel()
      }
    },
    cancel() {
      this.projectName = this.originProjectName
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style lang="scss">
@import 'style';
</style>
