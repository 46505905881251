<template>
  <CModal
    :visible="visible"
    class="add-team-modal"
    size="custom"
    backdrop="static"
    @close="closeModal"
  >
    <CModalHeader class="add-team-modal__header">
      <CModalTitle class="add-team-modal__heading">Add users</CModalTitle>
    </CModalHeader>
    <CModalBody class="add-team-modal__body">
      <CNInputSearch
        :model-value="modelValue"
        class="add-team-modal__search"
        @update:modelValue="emitSearchQuery($event)"
      />

      <SmartTable
        class="add-team-modal__table"
        :items="notAddedUsers"
        :columns="teamTableColumns"
        selectable="user_id"
        :selected-items="selectedUsers"
        infinity
        :loading="false"
        :sorter-value="sort"
        :clear-text="'No More Users To Add'"
        @selected-items-change="updateSelectedUsers"
        @sorter-change="onSort"
      ></SmartTable>

      <div class="add-team-modal__actions">
        <CButton
          class="add-team-modal__button"
          color="primary"
          variant="outline"
          @click="closeModal"
        >
          <span>Cancel</span>
        </CButton>

        <CButton
          class="add-team-modal__button"
          color="primary"
          :disabled="!selectedUsers.length"
          @click="modalHandler"
        >
          <span>Add all selected</span>
        </CButton>
      </div>
    </CModalBody>
  </CModal>
</template>

<script>
import table from '@/mixin/table'
export default {
  name: 'Modal',
  mixins: [table],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    notAddedUsers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['closeModal', 'modalHandler', 'update:modelValue', 'sort'],
  data() {
    return {
      teamTableColumns: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'role',
          label: 'Role',
        },
        {
          key: 'job_title',
          label: 'Job',
        },
        {
          key: 'can_approve_and_assign',
          label: 'Signer',
          format: (value) => (value ? 'Yes' : 'No'),
        },
        {
          key: 'office_name',
          label: 'Office',
        },
      ],
      selectedUsers: [],
    }
  },
  watch: {
    notAddedUsers(newUsers) {
      if (!newUsers) return
      this.clearModalData()
    },
    sort(sort) {
      this.$emit('sort', sort)
    },
  },
  methods: {
    closeModal() {
      this.clearModalData()
      this.$emit('closeModal')
    },
    modalHandler() {
      this.$emit('modalHandler', this.selectedUsers)
      this.clearModalData()
    },
    clearModalData() {
      this.selectedUsers = []
    },
    emitSearchQuery(value) {
      this.$emit('update:modelValue', value)
    },

    updateSelectedUsers(s) {
      this.selectedUsers = s
    },
  },
}
</script>

<style lang="scss">
.add-team-modal {
  .modal-custom {
    max-width: 880px;
  }

  &__heading {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
  }

  &__header {
    padding: 56px 40px 12px;
  }

  &__body {
    padding: 12px 40px 40px;
  }

  &__search {
    margin-bottom: 48px;
  }

  &__table {
    margin-bottom: 72px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    width: 200px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}
</style>
